@font-face {
	font-family: 'Roboto';
	src: url('./fonts/Roboto-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto-Medium';
	src: url('./fonts/Roboto-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto-Bold';
	src: url('./fonts/Roboto-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
